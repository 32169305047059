<template>
  <div class="col-12 col-md-12 col-lg-12 col-xl-8">
    <div class="border bg-white">
      <div>
        <div class="mt-4 px-4">
          <div class="stream--orders mb-0 border-0">
            <div class="d-flex justify-content-between align-items-center">
              <div class="">
                <p class="animated-background placeholder-title rounded-circle" style="height: 80px; width: 80px"></p>
              </div>
              <div class="d-flex align-items-center">
                <div class="info">
                  <p class="animated-background placeholder-title rounded" style="height: 20px; width: 200px"></p>
                  <p class="animated-background placeholder-title rounded" style="height: 20px; width: 180px"></p>
                  <p class="animated-background placeholder-title rounded" style="height: 20px; width: 160px"></p>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between mt-3">
              <div class="d-flex align-items-center">
                <div class="info">
                  <p class="animated-background placeholder-title rounded" style="height: 20px; width: 200px"></p>
                  <p class="animated-background placeholder-title rounded" style="height: 20px; width: 180px"></p>
                  <p class="animated-background placeholder-title rounded" style="height: 20px; width: 160px"></p>
                </div>
              </div>
            </div>

          </div>

          <div class="saved-order">
            <table class="table fixed_header table-borderless">
              <tbody>
              <tr v-for="number in 3"
                  :key="number">
                <td><p class="animated-background placeholder-title rounded" style="height: 20px; width: 200px"></p>
                </td>
                <td><p class="animated-background placeholder-title rounded" style="height: 20px; width: 200px"></p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvoiceShimmerLoader'
};
</script>

<style scoped>

</style>
