<template>
  <div class="col-12 col-md-12 col-lg-12 p-5 bg-white">
    <div class="text-center px-5">
      <div class="">
        <img src="https://designs.hubtel.com/v3/app/dist/images/svg/info.svg" width="100" />
      </div>

      <div class="px-3 pt-3">
        <div>
          <h6 class="font-weight-bold mb-1">Note</h6>
          <p>You have made a partial payment of <b>{{invoice.currency}} {{$filters.formatAmount(invoice.aggregatedAmount)}}</b>.
            An amount of <b>{{invoice.currency}} {{$filters.formatAmount(invoice.recurringAmount)}}</b> will be debited from your account <b>{{invoice.frequency?.toLowerCase()}}</b> until the payment is made in full.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AutomatedInvoiceSummary',
  computed: {
    ...mapGetters({
      invoice: 'invoiceModule/invoice'
    })
  },
  methods: {
    ...mapActions({
      submitAnalytics: 'invoiceModule/submitAnalytics'
    }),
    async logEvent (actionName, isSuccessful, actionProps) {
      try {
        const dateNow = new Date().toISOString();
        const eventPayload = {
          customer: { BusinessId: this.invoice.businessId, BusinessName: this.invoice.businessName },
          page: { ProjectName: 'Hubtel_Invoices', PageName: '/automated_invoice_summary', CreatedAt: dateNow },
          action: { Name: typeof actionName === 'string' ? actionName : '', EventSource: 'paylink_invoice', IsSuccessful: typeof isSuccessful === 'boolean' ? isSuccessful : false, ...actionProps }
        };
        await this.submitAnalytics(eventPayload);
      } catch (e) {
        console.log(e);
      }
    }
  },
  async created () {
    await this.logEvent('pay_now', true, { TraceId: this.invoice.id, amount: this.invoice.totalAmount });
  }
};
</script>

<style scoped>

</style>
