<template>
  <div class="col-12 col-md-12 col-lg-12 p-5 bg-white">
    <div class="text-center">
      <div class="">
        <img src="https://designs.hubtel.com/v3/app/dist/images/thumbs.png" class="w-25"/>
      </div>

      <div class="px-3 pt-3">
        <div>
          <h6 class="font-weight-bold mb-1">Payment Completed</h6>
          <p>Invoice has been paid in full</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSuccessCard'
};
</script>

<style scoped>

</style>
