import { initiate } from '@/api/checkout';

const checkoutModule = {
  namespaced: true,
  state () {
    return {};
  },
  actions: {
    initiateCheckout (context, payload) {
      return initiate(payload);
    }
  }
};

export default checkoutModule;
