import { createRouter, createWebHistory } from 'vue-router';
import homeRoutes from '@/router/routes/home';

const routes = [
  ...homeRoutes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { left: 0, top: 0 };
  }
});

export default router;
