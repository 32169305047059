<template>
  <div class="wrapper wrapper--no-sidebar">

    <div class="vh-100">
      <div class="row h-100 align-items-center justify-content-center no-gutters">
        <div class="col-12 col-sm-12 col-md-8 col-lg-5 col-xl-6">

          <div class="row justify-content-center">
          <router-view />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
