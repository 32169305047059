<template>
  <base-modal v-if="modalConfig.visible" @close="closeModal" :close-on-back-drop-clicked="false">
    <div class="text-center">
      <div class="mb-3">
        <img src="https://designs.hubtel.com/v3/app/dist/images/svg/failed.svg" alt="" class="mt-3" width="90">
      </div>
      <div>
        <p> {{ modalConfig.message }}
        </p>
      </div>
      <div class="mt-4">
        <a role="button" class="btn btn-outline-primary" @click="closeModal">Close</a>
      </div>
    </div>
  </base-modal>
  <invoice-shimmer-loader v-if="isLoading"></invoice-shimmer-loader>
  <component v-else :is="currentComponent"></component>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InvoiceShimmerLoader from '@/components/invoice/InvoiceShimmerLoader';
import InvoiceCard from '@/components/invoice/InvoiceCard';
import BaseSuccessCard from '@/components/ui/BaseSuccessCard';
import BaseModal from '@/components/ui/BaseModal';
import AutomatedInvoiceSummary from '@/components/invoice/AutomatedInvoiceSummary';

export default {
  name: 'Index',
  components: { InvoiceShimmerLoader, InvoiceCard, BaseSuccessCard, BaseModal, AutomatedInvoiceSummary },
  computed: {
    ...mapGetters({
      invoice: 'invoiceModule/invoice',
      currentComponent: 'currentComponent',
      modalConfig: 'modalConfig'
    })
  },
  data () {
    return {
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      fetchInvoice: 'invoiceModule/fetchInvoice',
      initiateCheckout: 'checkoutModule/initiateCheckout',
      changeCurrentComponent: 'changeCurrentComponent'
    }),
    closeModal () {
      this.$store.commit('setModalConfig', {
        visible: false,
        message: ''
      });
    }
  },
  async created () {
    try {
      this.isLoading = true;
      await this.fetchInvoice(this.$route.params.id);
      if (this.invoice.invoiceType?.toLowerCase() === 'full_invoice' && this.invoice.firstPaymentStatus?.toLowerCase() === 'paid') {
        this.changeCurrentComponent('BaseSuccessCard');
      } else if (this.invoice.invoiceType?.toLowerCase() === 'automated_debit_invoice' && this.invoice.firstPaymentStatus?.toLowerCase() === 'paid' && this.invoice.invoiceStatus?.toLowerCase() !== 'paid') {
        this.changeCurrentComponent('AutomatedInvoiceSummary');
      } else {
        this.changeCurrentComponent('InvoiceCard');
      }
      this.isLoading = false;
    } catch (e) {
    }
  }
};
</script>

<style scoped>

</style>
