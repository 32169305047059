<template>
  <div class="col-12 col-md-12 col-lg-12 col-xl-8">
    <div class="border bg-white">
      <div>
        <div class="mt-4 px-4">
          <div class="stream--orders mb-0 border-0" style="font-family: 'Courier New', Courier, monospace;">
            <div class="d-flex justify-content-between align-items-center">
              <div class="">
                <img :src="invoice.businessLogoUrl" class="img-fluid rounded-circle object-fit-contain" width="65" height="65" />
              </div>
              <div class="d-flex align-items-center">
                <div class="info text-right">
                  <h6 class="font-weight-bold mb-0">{{ invoice.businessName }}</h6>
                  <p class="mb-0">{{ invoice.businessPhoneNumber }}</p>
                  <p class="mb-0">{{ invoice.formattedCreatedAt }}</p>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between mt-3">
              <div class="info">
                <p class="mb-0 font-weight-bold">Bill To:</p>
                <p class="mb-0">{{ invoice.customerName }}</p>
                <p class="mb-0">{{ invoice.customerPhoneNumber }}</p>
              </div>
              <div class="d-flex align-items-center">
                <div class="info text-right">
                  <h3 class=" mb-0 font-weight-bold">INVOICE</h3>
                  <p class="mb-0">Invoice: <span class="font-weight-bold">{{ invoice.invoiceNumber }}</span></p>
                  <p class="mb-0">Due Date: <span class="font-weight-bold">{{ formatDate(invoice.invoiceEndDate) }}</span> </p>
                </div>
              </div>
            </div>
          </div>
          <div class="saved-order">
            <table class="table fixed_header table-borderless">
              <thead class="border-bottom bg-grey">
              <tr>
                <th scope="col" class="pr-0 text-body font-weight-bold">Qty</th>
                <th scope="col" class="pr-0 text-body font-weight-bold">Description</th>
                <th scope="col" class="text-body font-weight-bold text-right">Amount</th>
              </tr>
              </thead>
              <tbody class="border-bottom" id="scroll">
              <tr v-for="(item, index) in invoice.itemDetails" :key="index">
                <td>{{ item.quantity }}</td>
                <td>{{ item.itemDescription }}</td>
                <td class="pr-1"><span class="currency">{{ invoice.currency ?? 'GHS' }}</span>{{
                    $filters.formatAmount(item.amount)
                  }}
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="2" class="text-muted text-right w-75">Subtotal</td>
                <td class="pr-2"><span
                    class="currency">{{ invoice.currency ?? 'GHS' }}</span>{{ $filters.formatAmount(invoice.subTotal) }}
                </td>
              </tr>
              <tr v-for="(tax, index) in invoice.taxDetails" :key="index">
                <td colspan="2" v-if="tax.taxType && tax.taxType === 'flat'" class="text-muted text-right w-75">
                  {{ tax.taxName }}
                </td>
                <td colspan="2" v-else-if="tax.taxType && tax.taxType === 'percentage'"
                    class="text-muted text-right w-75">{{ tax.taxName }} ({{ tax.amount }}%)
                </td>
                <td class="pr-2" v-if="tax.taxType && tax.taxType === 'flat'"><span
                    class="currency">{{ invoice.currency ?? 'GHS' }}</span>{{ $filters.formatAmount(tax.amount) }}
                </td>
                <td class="pr-2" v-else-if="tax.taxType && tax.taxType === 'percentage'">
                  <span class="currency">{{
                      invoice.currency
                    }}</span><span>{{ $filters.formatAmount((invoice.subTotal * (tax.amount / 100))) }}</span>
                </td>
                <td class="pr-2" v-else><span class="currency">{{
                    invoice.currency ?? 'GHS'
                  }}</span>{{ $filters.formatAmount(tax.amount) }}
                </td>
              </tr>
              <tr v-for="(tax, index) in invoice.discountDetails" :key="index">
                <td colspan="2" v-if="tax.discountType && tax.discountType === 'flat'" class="text-muted text-right w-75">
                  {{ tax.discountName }}
                </td>
                <td colspan="2" v-else-if="tax.discountType && tax.discountType === 'percentage'"
                    class="text-muted text-right w-75">{{ tax.discountName }} ({{ tax.amount }}%)
                </td>
                <td class="pr-2" v-if="tax.discountType && tax.discountType === 'flat'"><span
                    class="currency">{{ invoice.currency ?? 'GHS' }}</span>{{ $filters.formatAmount(tax.amount) }}
                </td>
                <td class="pr-2" v-else-if="tax.discountType && tax.discountType === 'percentage'">
                  <span class="currency">{{
                      invoice.currency
                    }}</span><span>{{ $filters.formatAmount((invoice.subTotal * (tax.amount / 100))) }}</span>
                </td>
                <td class="pr-2" v-else><span class="currency">{{
                    invoice.currency ?? 'GHS'
                  }}</span>{{ $filters.formatAmount(tax.amount) }}
                </td>
              </tr>
              <tr>
                <td colspan="2" class="font-wieght-bold text-right w-75">Total</td>
                <td class="font-weight-bold h5 pr-2"><span class="currency">{{
                    invoice.currency ?? 'GHS'
                  }}</span>{{ $filters.formatAmount(invoice.totalAmount) }}
                </td>
              </tr>
              <tr class="border-top border-dark"  v-if="invoice.invoiceType && invoice.invoiceType.toLowerCase() === 'automated_debit_invoice'">
                <td colspan="3" class="pt-5">
                  <div class="info text-wrap text-left" style="font-family: 'Courier New', Courier, monospace;">
                    <h6 class="font-weight-bold">NOTE:</h6>
                    <p class="mb-0">This is a bill that’s automatically charged to you. You need to pay <span class="font-weight-bold">{{invoice.currency}} {{$filters.formatAmount(invoice.firstPaymentAmount)}}</span> now, and then you’ll be charged <span class="font-weight-bold">{{invoice.currency}} {{$filters.formatAmount(invoice.recurringAmount)}}</span> {{invoice.frequency?.toLowerCase()}} <span class="font-weight-bold">until {{formatDate(invoice.invoiceEndDate)}}.</span></p>
                  </div>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row align-items-center justify-content-center">
        <div class="pt-4">
          <button type="button" class="btn d-block btn-primary px-5" @click="createCheckoutRequest"
                  :disabled="isCreatingRequest">
            <span v-if="isCreatingRequest" class="fa fa-spin fa-spinner"></span> Pay Now
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'InvoiceCard',
  computed: {
    ...mapGetters({
      invoice: 'invoiceModule/invoice'
    })
  },
  data () {
    return {
      isCreatingRequest: false,
      producerCheckoutBaseUrl: process.env.VUE_APP_PRODUCER_CHECKOUT_BASE_URL,
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      initiateCheckout: 'checkoutModule/initiateCheckout',
      submitAnalytics: 'invoiceModule/submitAnalytics'
    }),
    async logEvent (actionName, isSuccessful, actionProps) {
      try {
        const dateNow = new Date().toISOString();
        const eventPayload = {
          customer: { BusinessId: this.invoice.businessId, BusinessName: this.invoice.businessName },
          page: { ProjectName: 'Hubtel_Invoices', PageName: '/Invoice_summary', CreatedAt: dateNow },
          action: { Name: typeof actionName === 'string' ? actionName : '', EventSource: 'paylink_invoice', IsSuccessful: typeof isSuccessful === 'boolean' ? isSuccessful : false, ...actionProps }
        };
        await this.submitAnalytics(eventPayload);
      } catch (e) {
        console.log(e);
      }
    },
    async createCheckoutRequest () {
      try {
        this.isCreatingRequest = true;
        const response = await this.initiateCheckout(this.$route.params.id);
        await this.logEvent('pay_now', true, { TraceId: this.invoice.id, amount: this.invoice.totalAmount });
        const orderId = response.data.data.orderId;
        location.assign(`${this.producerCheckoutBaseUrl}/${orderId}`);
      } catch (e) {
        this.isCreatingRequest = false;
      }
    },
    formatDate (date) {
      return new Date(date).toLocaleDateString();
    }
  }
};
</script>

<style scoped>

</style>
