import { getInvoice, addAnalytics } from '@/api/invoice';

const invoiceModule = {
  namespaced: true,
  state () {
    return {
      invoice: {}
    };
  },
  mutations: {
    setInvoice (state, payload) {
      state.invoice = payload;
    }
  },
  actions: {
    async fetchInvoice (context, payload) {
      const response = await getInvoice(payload);
      context.commit('setInvoice', response.data.data);
    },
    async submitAnalytics (context, payload) {
      return await addAnalytics(payload);
    }
  },
  getters: {
    invoice (state) {
      return state.invoice;
    }
  }
};

export default invoiceModule;
