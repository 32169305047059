import httpClient from '@/api/httpClient';

const BASE_URL = '/api/invoice';

const getInvoice = (invoiceId) => httpClient.get(`${BASE_URL}/${invoiceId}`);
const addAnalytics = (payload) => httpClient.post(`${BASE_URL}/event-analytics`, payload);

export {
  getInvoice,
  addAnalytics
};
