const numeral = require('numeral');

const formatAmount = (amount) => {
  if (amount === 0 || amount) {
    return numeral(amount).format('0,0.00');
  }
  return amount;
};

export {
  formatAmount
};
