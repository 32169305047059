import axios from 'axios';
import { LoggerFactory } from '../utils/logging/loggerFactory';
import store from '@/store';

const httpClient = axios.create({});
const logger = LoggerFactory.create();
httpClient.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
httpClient.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const response = error.response;
    if (response) {
      await handleError(response);
    }

    return Promise.reject(error);
  }
);

const handleError = async (error) => {
  logger.error(error, 'Global Error Handler');
  if (error && error.status === 404) {
    await handle404Errors(error);
  } else {
    await handleOtherErrors(error);
  }
};

const handle404Errors = async (error) => {
  await store.dispatch('displayModal', {
    visible: true,
    message: error.data.message ?? 'Sorry, we couldn\'t find the invoice you’re looking for. This invoice may have been deleted or does not exist.'
  });
};

const handleOtherErrors = async (error) => {
  await store.dispatch('displayModal', {
    visible: true,
    message: error.data.message ?? 'Something went Wrong. Try doing this again later'
  });
};

export default httpClient;
