import { createStore } from 'vuex';
import invoiceModule from '@/store/modules/invoice';
import checkoutModule from '@/store/modules/checkout';

export default createStore({
  state () {
    return {
      currentComponent: null,
      modalConfig: {
        visible: false,
        message: ''
      }
    };
  },
  mutations: {
    setCurrentComponent (state, payload) {
      state.currentComponent = payload;
    },
    setModalConfig (state, payload) {
      state.modalConfig = payload;
    }
  },
  actions: {
    changeCurrentComponent (context, payload) {
      context.commit('setCurrentComponent', payload);
    },
    displayModal (context, payload) {
      context.commit('setModalConfig', payload);
    }
  },
  getters: {
    currentComponent (state) {
      return state.currentComponent;
    },
    modalConfig (state) {
      return state.modalConfig;
    }
  },
  modules: {
    invoiceModule,
    checkoutModule
  }
});
