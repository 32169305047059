import HomeIndex from '@/views/home/Index';

const homeRoutes = [
  {
    path: '/:id',
    name: 'Home',
    component: HomeIndex
  }
];

export default homeRoutes;
